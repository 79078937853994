import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getChatData, getNotifications ,userData,staffData} from '../../redux/firebase/firestore/actionCreator';

function ProtectedRoute({ component, path }) {
  const dispatch = useDispatch();

  const { uid } = useSelector((state) => {
    return {
      uid: state.firebaseAuth.data.uid,
    };
  });

  useEffect(() => {
    let unsubscribe;
    const handleNotifications = async () => {
      dispatch(getNotifications());
    };
    handleNotifications();
    return () => {
      unsubscribe?.();
    };
  }, []);

  useEffect(() => {
    let unsubscribe;
    const handleUser = async () => {
      dispatch(getChatData());
    };
    handleUser();
    return () => {
      unsubscribe?.();
    };
  }, []);

  useEffect(() => {
    let unsubscribe;
    const handleUserData = async () => {
      dispatch(userData(uid));
    };
    handleUserData();
    return () => {
      unsubscribe?.();
    };
  }, [uid]);

  useEffect(() => {
    let unsubscribe;
    const handleStaffData = async () => {
      dispatch(staffData());
    };
    handleStaffData();
    return () => {
      unsubscribe?.();
    };
  }, []);



  const isLoggedIn = useSelector((state) => state.firebaseAuth.isLogin);
  return isLoggedIn ? <Route component={component} path={path} /> : <Redirect to="/" />;
}

ProtectedRoute.propTypes = {
  component: propTypes.object.isRequired,
  path: propTypes.string.isRequired,
};

export default ProtectedRoute;
