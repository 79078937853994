/* eslint-disable no-unused-vars */
import { notification, message } from 'antd';
import firebase from 'firebase';
import actions from './actions';

const addNotificationSuccess = () => {
  notification.success({
    message: 'Your Record hasbeen Submited',
  });
};

const addNotificationError = (err) => {
  notification.error({
    message: err,
  });
};

const deleteNotificationSuccess = () => {
  notification.success({
    message: 'Your Record hasbeen Deleted',
  });
};

const deleteNotificationError = (err) => {
  notification.error({
    message: err,
  });
};

const updateNotificationSuccess = () => {
  notification.success({
    message: 'Your Record hasbeen updated',
  });
};

const updateNotificationError = (err) => {
  notification.error({
    message: err,
  });
};

const {
  fbAddBegin,
  fbAddSuccess,
  fbAddErr,

  supportTicketBegin,
  supportTicketSuccess,
  supportTicketErr,

  fbReadBegin,
  fbReadSuccess,
  fbReadErr,

  fbUpdateBegin,
  fbUpdateSuccess,
  fbUpdateErr,

  fbDeleteBegin,
  fbDeleteSuccess,
  fbDeleteErr,

  fbSingleDataBegin,
  fbSingleDataSuccess,
  fbSingleDataErr,

  fbUploadBegin,
  fbUploadSuccess,
  fbUploadErr,

  fbSearchBegin,
  fbSearchSuccess,
  fbSearchErr,

  newsDataBegin,
  newsDataSuccess,
  newsDataErr,

  staffDataBegin,
  staffDataSuccess,
  staffDataErr,

  staffGeofenceDataBegin,
  staffGeofenceDataSuccess,
  staffGeofenceDataErr,

  staffListDataBegin,
  staffListDataSuccess,
  staffListDataErr,

  categoryTypesBegin,
  categoryTypesSuccess,
  categoryTypesErr,

  clientCategoryTypesBegin,
  clientCategoryTypesSuccess,
  clientCategoryTypesErr,

  userProfileDataBegin,
  userProfileDataSuccess,
  userProfileDataErr,

  chatDataBegin,
  chatDataSuccess,
  chatDataErr,

  userDataBegin,
  userDataSuccess,
  userDataErr,

  servicesDataBegin,
  servicesDataSuccess,
  servicesDataErr,

  allUsersDataBegin,
  allUsersDataSuccess,
  allUsersDataErr,

  getNotificationsBegin,
  getNotificationsSuccess,
  getNotificationsErr,

  staffDocumentDataBegin,
  staffDocumentDataSuccess,
  staffDocumentDataErr,

  checkAssignmentRangeBegin,
  checkAssignmentRangeSuccess,
  checkAssignmentRangeErr,
} = actions;

const getNotifications = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    try {
      await dispatch(getNotificationsBegin());

      const notificationsRef = db.collection('notifications');
      const currentUserUid = firebase.auth().currentUser.uid;

      notificationsRef
        .where('to', 'in', [currentUserUid, ''])
        .orderBy('createdAt', 'desc')
        .onSnapshot(async (querySnapshot) => {
          const promises = querySnapshot.docs.map(async (doc) => {
            if (doc.data().type !== 'announcement') {
              const fromPromise = db.collection('users').doc(doc.data().from).get();
              const toPromise = db.collection('users').doc(doc.data().to).get();
              const [from, to] = await Promise.all([fromPromise, toPromise]);
              return {
                id: doc.id,
                ...doc.data(),
                notificationFrom:
                  from.data()?.role === 'client'
                    ? from.data().facility_name
                    : `${from.data().first_name} ${from.data().last_name}`,
                notificationTo:
                  to.data()?.role === 'client'
                    ? to.data().facility_name
                    : `${to.data().first_name} ${to.data().last_name}`,
              };
            }
            if (doc.data().status !== 'disabled') {
              return {
                id: doc.id,
                ...doc.data(),
              };
            }
            return null;
          });
          // const array = await Promise.all(promises);
          const array = (await Promise.all(promises)).filter((item) => item !== null);

          const currentUserNotificationsRef = notificationsRef.where('from', '==', currentUserUid);
          currentUserNotificationsRef.orderBy('createdAt', 'desc').onSnapshot(async (querySnapshot) => {
            const promises = querySnapshot.docs.map(async (doc) => {
              const fromPromise = db.collection('users').doc(doc.data().from).get();
              const toPromise = db.collection('users').doc(doc.data().to).get();
              const [from, to] = await Promise.all([fromPromise, toPromise]);
              return {
                id: doc.id,
                ...doc.data(),
                notificationFrom:
                  from.data()?.role === 'client'
                    ? from.data().facility_name
                    : `${from.data().first_name} ${from.data().last_name}`,
                notificationTo:
                  to.data()?.role === 'client'
                    ? to.data().facility_name
                    : `${to.data().first_name} ${to.data().last_name}`,
              };
            });
            const array2 = await Promise.all(promises);
            const combinedArray = [...array, ...array2];
            const sortedArray = combinedArray.sort((a, b) => b.createdAt - a.createdAt);
            dispatch(getNotificationsSuccess(sortedArray));
          });
        });
    } catch (err) {
      await dispatch(getNotificationsErr(err));
    }
  };
};

const allUsersData = () => {
  return async (dispatch) => {
    try {
      await dispatch(allUsersDataBegin());
      firebase
        .firestore()
        .collection('users')
        .where('status', '!=', 'disabled')
        .onSnapshot((querySnapshot) => {
          const array = [];
          // eslint-disable-next-line array-callback-return
          querySnapshot.docs.map((doc, index) => {
            if (doc.data().category) {
              firebase
                .firestore()
                .collection('categories')
                .doc(doc.data().category)
                .get()
                .then((cat) => {
                  array.push({ id: doc.id, ...doc.data(), categoryData: cat.data()?.title });
                  if (querySnapshot.docs.length - 1 === index) {
                    dispatch(allUsersDataSuccess(array));
                  }
                });
            } else {
              array.push({ id: doc.id, ...doc.data(), categoryData: '-' });
              if (querySnapshot.docs.length - 1 === index) {
                dispatch(allUsersDataSuccess(array));
              }
            }
          });
        });
    } catch (err) {
      await dispatch(allUsersDataErr(err));
    }
  };
};

const getStaffData = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    try {
      await dispatch(staffListDataBegin());

      firebase
        .firestore()
        .collection('users')
        .doc(firebase.auth().currentUser.uid)
        .collection('assignedStaff')
        .onSnapshot(async (querySnapshot) => {
          const array = [];
          // eslint-disable-next-line array-callback-return
          const promises = querySnapshot.docs.map(async (val) => {
            const cat = await firebase.firestore().collection('users').doc(val.data().staffId).get();
            return {
              id: cat.data().id,
              first_name: cat.data().first_name,
              last_name: cat.data().last_name,
            };
          });
          const staffData = await Promise.all(promises);
          dispatch(staffListDataSuccess(staffData));
        });
    } catch (err) {
      await dispatch(staffListDataErr(err));
    }
  };
};

const checkGeofenceActiveAssignments = (todaysDate) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    try {
      await dispatch(checkAssignmentRangeBegin());
      db.collection('assignments')
        .where('date', '==', todaysDate)
        .where('status', '==', 'active')
        .where('client_id', '==', firebase.auth().currentUser.uid)
        .onSnapshot((querySnapshot) => {
          const snap = querySnapshot.docs.map((doc) => {
            return { id: doc.id, ...doc.data() };
          });
          dispatch(checkAssignmentRangeSuccess(snap));
        });
    } catch (err) {
      await dispatch(checkAssignmentRangeErr(err));
    }
  };
};

const servicesData = () => {
  return async (dispatch) => {
    try {
      await dispatch(servicesDataBegin());
      firebase
        .firestore()
        .collection('services')
        .where('status', '!=', 'disabled')
        .onSnapshot((querySnapshot) => {
          const snap = querySnapshot.docs.map((doc) => {
            return { id: doc.id, ...doc.data() };
          });
          dispatch(servicesDataSuccess(snap));
        });
    } catch (err) {
      await dispatch(servicesDataErr(err));
    }
  };
};

const userData = (id) => {
  return async (dispatch) => {
    try {
      await dispatch(userDataBegin());
      const docRef = firebase.firestore().collection('users').doc(id);
      docRef.onSnapshot((doc) => {
        if (doc.exists) {
          firebase
            .firestore()
            .collection('categories')
            .doc(doc.data().category)
            .get()
            .then((cat) => {
              dispatch(userDataSuccess({ ...doc.data(), categoryName: cat.data().title }));
            });
        } else {
          console.log('No such document!');
        }
      });
    } catch (err) {
      await dispatch(userDataErr(err));
    }
  };
};

const getChatData = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    try {
      await dispatch(chatDataBegin());
      db.collection('chats')
        .where('memberIds', 'array-contains', firebase.auth().currentUser.uid)
        .onSnapshot((querySnapshot) => {
          const snap = querySnapshot.docs.map((doc) => {
            return { id: doc.id, ...doc.data() };
          });
          dispatch(chatDataSuccess(snap));
        });
    } catch (err) {
      await dispatch(chatDataErr(err));
    }
  };
};

const createChat = (data) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const fb = getFirebase();
    try {
      const chat = db.collection('chats').add(data);
      return chat;
    } catch (err) {
      console.log(err);
    }
  };
};

const createSupportTicket = (data) => {
  return async (dispatch, getState, { getFirestore }) => {
    const db = getFirestore();
    try {
      await dispatch(supportTicketBegin());
      db.collection('mail')
        .add({
          message: {
            subject: `Request Support from: ${data.email} - ${data.subject}`,
            text: data.text,
          },
          to: 'support@elitetaskforce.ca',
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          createdBy: firebase.auth().currentUser.uid,
        })
        .then(() => {
          message.success('Support Form Submitted Successfully');
          dispatch(supportTicketSuccess());
        });
    } catch (err) {
      console.log(err);
      await dispatch(supportTicketErr(err));
    }
  };
};

const getCategoryTypes = () => {
  return async (dispatch) => {
    try {
      await dispatch(categoryTypesBegin());
      firebase
        .firestore()
        .collection('categories')
        .where('status', 'not-in', ['inactive', 'disabled'])
        .where('type', '==', 'Staff')
        .onSnapshot((querySnapshot) => {
          const snap = querySnapshot.docs.map((doc) => {
            return { id: doc.id, name: doc.data().title };
          });

          dispatch(categoryTypesSuccess(snap));
        });
    } catch (err) {
      await dispatch(categoryTypesErr(err));
    }
  };
};

const getClientCategoryTypes = () => {
  return async (dispatch) => {
    try {
      await dispatch(clientCategoryTypesBegin());
      firebase
        .firestore()
        .collection('categories')
        .where('status', 'not-in', ['inactive', 'disabled'])
        .where('type', '==', 'Client')
        .onSnapshot((querySnapshot) => {
          const snap = querySnapshot.docs.map((doc) => {
            return { id: doc.id, name: doc.data().title };
          });
          dispatch(clientCategoryTypesSuccess(snap));
        });
    } catch (err) {
      await dispatch(clientCategoryTypesErr(err));
    }
  };
};
// staff data on table
const staffData = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    try {
      await dispatch(staffDataBegin());
      const currentUserUid = getFirebase().auth().currentUser.uid;

      db.collection('users')
        .doc(currentUserUid)
        .collection('assignedStaff')
        .onSnapshot(async (querySnapshot) => {
          const promises = querySnapshot.docs.map(async (val) => {
            const { staffId } = val.data();
            const data = await db.collection('users').doc(staffId).collection('ratings').doc(currentUserUid).get();

            const doc = await db.collection('users').doc(staffId).get();
            const categoryDoc = await db.collection('categories').doc(doc.data().category).get();

            return {
              id: doc.id,
              ...doc.data(),
              categoryData: categoryDoc.data().title,
              preferredStaff: val.data().preferredStaff,
              assignedStaffId: val.id,
              rating: data.data()?.rating,
            };
          });

          const array = await Promise.all(promises);
          dispatch(staffDataSuccess(array));
        });
    } catch (err) {
      await dispatch(staffDataErr(err));
    }
  };
};

const userProfileData = (id) => {
  return async (dispatch) => {
    try {
      await dispatch(userProfileDataBegin());
      firebase
        .firestore()
        .collection('users')
        .where('id', '==', id)
        .onSnapshot(async (querySnapshot) => {
          const promises = querySnapshot.docs.map(async (doc) => {
            const userDoc = doc.data();
            const categoryDoc = await firebase.firestore().collection('categories').doc(userDoc.category).get();
            const categoryData = categoryDoc.data();
            return {
              ...userDoc,
              categoryName: categoryData.title,
            };
          });
          const snap = await Promise.all(promises);
          dispatch(userProfileDataSuccess(snap));
        });
    } catch (err) {
      await dispatch(userProfileDataErr(err));
    }
  };
};

const getNewsData = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    try {
      await dispatch(newsDataBegin());
      db.collection('news')
        .where('status', '!=', 'disabled')
        .onSnapshot((querySnapshot) => {
          const snap = querySnapshot.docs.map((doc) => {
            return { id: doc.id, ...doc.data() };
          });
          dispatch(newsDataSuccess(snap));
        });
    } catch (err) {
      await dispatch(newsDataErr(err));
    }
  };
};

const fbDataSubmit = (data) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    try {
      await dispatch(fbAddBegin());
      await db.collection('crud').doc(`${data.id}`).set(data);
      await dispatch(fbAddSuccess(data));
      await addNotificationSuccess();
    } catch (err) {
      await dispatch(fbAddErr(err));
      await addNotificationError(err);
    }
  };
};

const fbDataRead = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const data = [];
    try {
      await dispatch(fbReadBegin());
      const query = await db.collection('crud').get();
      await query.forEach((doc) => {
        data.push(doc.data());
      });
      await dispatch(fbReadSuccess(data));
    } catch (err) {
      await dispatch(fbReadErr(err));
    }
  };
};

const fbDataSearch = (value) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const data = [];
    try {
      await dispatch(fbSearchBegin());
      const query = await db.collection('crud').get();
      await query.forEach((doc) => {
        data.push(doc.data());
      });
      const searchValue = data.filter((item) => item.name.toLowerCase().startsWith(value.toLowerCase()));
      await dispatch(fbSearchSuccess(searchValue));
    } catch (err) {
      await dispatch(fbSearchErr(err));
    }
  };
};

const updateData = (collection, id, data) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    try {
      await dispatch(fbUpdateBegin());
      await firebase
        .firestore()
        .collection(collection)
        .doc(`${id}`)
        .update({
          ...data,
          unit: data.unit ? data.unit : '',
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedBy: firebase.auth().currentUser.uid,
        })
        .then(() => {
          // console.log('Update Success');
          dispatch(fbUpdateSuccess());
        });
    } catch (err) {
      await dispatch(fbUpdateErr(err));
    }
  };
};

const fbDataDelete = (id) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const data = [];
    try {
      await dispatch(fbDeleteBegin());
      await db.collection('crud').doc(`${id}`).delete();
      const query = await db.collection('crud').get();
      await query.forEach((doc) => {
        data.push(doc.data());
      });
      await dispatch(fbDeleteSuccess(data));
      await deleteNotificationSuccess();
      await fbDataRead();
    } catch (err) {
      await dispatch(fbDeleteErr(err));
      await deleteNotificationError(err);
    }
  };
};

const fbDataSingle = (id) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    try {
      await dispatch(fbSingleDataBegin());
      const query = await db.collection('crud').where('id', '==', id).get();
      await query.forEach((doc) => {
        dispatch(fbSingleDataSuccess(doc.data()));
      });
    } catch (err) {
      await dispatch(fbSingleDataErr(err));
    }
  };
};

const fbFileUploder = (imageAsFile) => {
  return async (dispatch, getState, { getFirebase, getFirestore, storage }) => {
    try {
      await dispatch(fbUploadBegin());
      const uploadTask = storage().ref(`/images/${imageAsFile.name}`).put(imageAsFile);

      await uploadTask.on(
        'state_changed',
        (snapShot) => {
          // takes a snap shot of the process as it is happening
          console.log(snapShot);
        },
        (err) => {
          // catches the errors
          console.log(err);
        },
        () => {
          storage()
            .ref('images')
            .child(imageAsFile.name)
            .getDownloadURL()
            .then((fireBaseUrl) => {
              dispatch(fbUploadSuccess(fireBaseUrl));
            });
        },
      );
    } catch (err) {
      await dispatch(fbUploadErr(err));
    }
  };
};

const fbFileClear = () => {
  return async (dispatch) => {
    try {
      await dispatch(fbUploadBegin());
      dispatch(fbUploadSuccess(null));
    } catch (err) {
      await dispatch(fbUploadErr(err));
    }
  };
};

const staffDocumentData = (staffId) => {
  return async (dispatch) => {
    try {
      await dispatch(staffDocumentDataBegin());
      firebase
        .firestore()
        .collection('users')
        .doc(staffId)
        .collection('documents')
        .where('status', '!=', 'disabled')
        .onSnapshot((querySnapshot) => {
          const snap = querySnapshot.docs.map((doc) => {
            return { id: doc.id, ...doc.data() };
          });
          dispatch(staffDocumentDataSuccess(snap));
        });
    } catch (err) {
      await dispatch(staffDocumentDataErr(err));
    }
  };
};

const checkAssignmentRange = (staffId, todaysDate) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    try {
      await dispatch(checkAssignmentRangeBegin());
      db.collection('assignments')
        .where('date', '==', todaysDate)
        .where('client_id', '==', firebase.auth().currentUser.uid)
        .where('staff_id', '==', staffId)
        .where('status', '==', 'active')
        .onSnapshot((querySnapshot) => {
          const snap = querySnapshot.docs.map((doc) => {
            return { id: doc.id, ...doc.data() };
          });
          dispatch(checkAssignmentRangeSuccess(snap));
        });
    } catch (err) {
      await dispatch(checkAssignmentRangeErr(err));
    }
  };
};

const getStaffGeofenceData = (id) => {
  return async (dispatch) => {
    try {
      await dispatch(staffGeofenceDataBegin());
      firebase
        .firestore()
        .collection('users')
        .where('id', '==', id)
        .onSnapshot((querySnapshot) => {
          const snap = querySnapshot.docs.map((doc) => {
            return { id: doc.id, ...doc.data() };
          });
          dispatch(staffGeofenceDataSuccess(snap));
        });
    } catch (err) {
      await dispatch(staffGeofenceDataErr(err));
    }
  };
};

export {
  getStaffGeofenceData,
  checkAssignmentRange,
  getNotifications,
  allUsersData,
  getChatData,
  userProfileData,
  getStaffData,
  getCategoryTypes,
  getClientCategoryTypes,
  updateData,
  getNewsData,
  fbDataSubmit,
  fbDataSearch,
  fbDataDelete,
  fbDataSingle,
  fbDataRead,
  servicesData,
  fbFileUploder,
  fbFileClear,
  staffData,
  createChat,
  userData,
  createSupportTicket,
  staffDocumentData,
  checkGeofenceActiveAssignments,
};
