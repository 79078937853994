import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import { readMessageReducer } from './message/reducers';
import { readNotificationReducer } from './notification/reducers';
import authReducer from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import { userReducer } from './users/reducers';
import { headerSearchReducer } from './headerSearch/reducers';
import { chatReducer, SingleChatReducer, groupChatReducer, SingleChatGroupReducer } from './chat/reducers';
import chartContentReducer from './chartContent/reducers';
import Calender from './calendar/reducers';
import Task from './task/reducers';
import cartData from './cart/reducers';
import firebaseAuth from './firebase/auth/reducers';
import { fsCrudReducer, fsSingleCrudReducer } from './firebase/firestore/reducers';

const rootReducers = combineReducers({

  fb: firebaseReducer,
  fs: firestoreReducer,
  firebaseAuth,
  auth: authReducer,
  headerSearchData: headerSearchReducer,
  message: readMessageReducer,
  notification: readNotificationReducer,
  users: userReducer,
  ChangeLayoutMode,
  chartContent: chartContentReducer,
  chatSingle: SingleChatReducer,
  chatSingleGroup: SingleChatGroupReducer,
  chat: chatReducer,
  groupChat: groupChatReducer,
  Task,
  Calender,
  cart: cartData,
  crud: fsCrudReducer,
  singleCrud: fsSingleCrudReducer,
});

export default rootReducers;
