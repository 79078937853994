import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import FeatherIcon from 'feather-icons-react';
import { Link, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Scrollbars } from 'react-custom-scrollbars';
import { useSelector } from 'react-redux';
import { AtbdTopDropdwon } from './auth-info-style';
import { Popover } from '../../popup/popup';
import Heading from '../../heading/heading';

function NotificationBox() {
  const { path } = useRouteMatch();
  const { rtl, notificationsData } = useSelector((state) => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
      notificationsData: state.crud.notificationsData,
    };
  });

  function renderThumb({ style, ...props }) {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  }

  const renderTrackVertical = () => {
    const thumbStyle = {
      position: 'absolute',
      width: '6px',
      transition: 'opacity 200ms ease 0s',
      opacity: 0,
      [rtl ? 'left' : 'right']: '2px',
      bottom: '2px',
      top: '2px',
      borderRadius: '3px',
    };
    return <div className="hello" style={thumbStyle} />;
  };

  function renderView({ style, ...props }) {
    const customStyle = {
      marginRight: rtl && 'auto',
      [rtl ? 'marginLeft' : 'marginRight']: '-17px',
    };
    return <div {...props} style={{ ...style, ...customStyle }} />;
  }

  const notificationAt = (notificationTime) => {
    const time = moment(notificationTime?.toDate());
    const formattedTime = time.format('HH:mm DD MMM YYYY');
    return formattedTime;
  };

  renderThumb.propTypes = {
    style: PropTypes.shape(PropTypes.object),
  };

  renderView.propTypes = {
    style: PropTypes.shape(PropTypes.object),
  };

  const content = (
    <AtbdTopDropdwon className="atbd-top-dropdwon">
      <Heading as="h5" className="atbd-top-dropdwon__title">
        <span className="title-text">Notifications</span>
      </Heading>
      <Scrollbars
        autoHeight
        autoHide
        renderThumbVertical={renderThumb}
        renderView={renderView}
        renderTrackVertical={renderTrackVertical}
      >
        <ul className="atbd-top-dropdwon__nav notification-list">
          {notificationsData.map((item, index) => (
            <li key={index}>
              <Link to="#">
                <div className="atbd-top-dropdwon__content notifications">
                  <div className="notification-icon bg-primary">
                    <FeatherIcon icon="file-text" />
                  </div>
                  <div className="notification-content d-flex">
                    <div className="notification-text">
                      {item.type === 'announcement' ? (
                        <Heading as="h5">
                          <div className="inline-text color-primary">{item.title}</div>
                          <div className="sDash_tasks-item__text">{ReactHtmlParser(item.description)}</div>
                        </Heading>
                      ) : item.type === 'update' ? (
                        <Heading as="h5">
                          <span>{item.notificationFrom}</span> {item.description}{' '}
                          {item.assignment_id ? item.assignment_id : null} for {item.notificationTo}
                        </Heading>
                      ) : (
                        <Heading as="h5">
                          <span>{item.notificationFrom}</span> {item.description}{' '}
                          {item.assignment_id ? item.assignment_id : null}{' '}
                          {item.description === 'has assigned a new Assignment' ? 'to' : 'of'} {item.notificationTo}
                        </Heading>
                      )}
                      <p>{notificationAt(item.createdAt)}</p>
                    </div>
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </Scrollbars>
      <Link className="btn-seeAll" to={`${path}/notifications`}>
        See all incoming activity
      </Link>
    </AtbdTopDropdwon>
  );

  return (
    <div className="notification">
      <Popover placement="bottomLeft" content={content} action="click">
        {/* <Badge dot offset={[-8, -5]} style={{ display: unreadCount ? 'inline-block' : 'none' }}> */}
        <Link to="#" className="head-example">
          <FeatherIcon icon="bell" size={20} />
        </Link>
        {/* </Badge> */}
      </Popover>
    </div>
  );
}

export default NotificationBox;
