import React from 'react';
import { Avatar } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { InfoWraper, UserDropDwon } from './auth-info-style';
import Notification from './notification';
// import Message from './message';
import { Popover } from '../../popup/popup';
import Heading from '../../heading/heading';
import { fbAuthLogout } from '../../../redux/firebase/auth/actionCreator';
import { UserImage } from '../../UserImage';

function AuthInfo() {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  const { usersData } = useSelector((state) => {
    return {
      usersData: state.crud.userData,
    };
  });

  const SignOut = (e) => {
    e.preventDefault();
    dispatch(fbAuthLogout());
  };

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          {/* {usersData.profile_pic ? (
            <img src={usersData.profile_pic} alt="" style={{ height: '48px', width: '48px' }} />
          ) : (
            <img src={require('../../../static/img/avatar/chat-auth.png')} alt="" />
          )} */}
          <UserImage url={usersData.profile_pic} imgStyle={{ height: '48px', width: '48px' }} />

          <figcaption>
            <Heading as="h5">{usersData.facility_name}</Heading>
            <p>{usersData.role}</p>
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <Link to={`${path}/settings/profile`}>
              <FeatherIcon icon="user" /> Profile
            </Link>
          </li>
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  return (
    <InfoWraper>
      {/* <Message /> */}
      <Notification />

      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            <Avatar src={usersData.profile_pic} alt="Profile" />
            {/* <UserImage url={imgUrl} /> */}
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
}

export default AuthInfo;
