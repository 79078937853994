import actions from './actions';

const {
  FB_ADD_BEGIN,
  FB_ADD_SUCCESS,
  FB_ADD_ERR,

  SUPPORT_TICKET_BEGIN,
  SUPPORT_TICKET_SUCCESS,
  SUPPORT_TICKET_ERR,

  FB_UPLOAD_BEGIN,
  FB_UPLOAD_SUCCESS,
  FB_UPLOAD_ERR,

  FB_READ_BEGIN,
  FB_READ_SUCCESS,
  FB_READ_ERR,

  FB_UPDATE_BEGIN,
  FB_UPDATE_SUCCESS,
  FB_UPDATE_ERR,

  FB_DELETE_BEGIN,
  FB_DELETE_SUCCESS,
  FB_DELETE_ERR,

  FB_SINGLE_DATA_BEGIN,
  FB_SINGLE_DATA_SUCCESS,
  FB_SINGLE_DATA_ERR,

  FB_SEARCH_BEGIN,
  FB_SEARCH_SUCCESS,
  FB_SEARCH_ERR,

  NEWS_DATA_BEGIN,
  NEWS_DATA_SUCCESS,
  NEWS_DATA_ERR,

  STAFF_DATA_BEGIN,
  STAFF_DATA_SUCCESS,
  STAFF_DATA_ERR,

  STAFF_GEOFENCE_DATA_BEGIN,
  STAFF_GEOFENCE_DATA_SUCCESS,
  STAFF_GEOFENCE_DATA_ERR,

  STAFF_LIST_DATA_BEGIN,
  STAFF_LIST_DATA_SUCCESS,
  STAFF_LIST_DATA_ERR,

  CATEGORY_TYPES_BEGIN,
  CATEGORY_TYPES_SUCCESS,
  CATEGORY_TYPES_ERR,

  CLIENT_CATEGORY_TYPES_BEGIN,
  CLIENT_CATEGORY_TYPES_SUCCESS,
  CLIENT_CATEGORY_TYPES_ERR,

  USER_PROFILE_DATA_BEGIN,
  USER_PROFILE_DATA_SUCCESS,
  USER_PROFILE_DATA_ERR,

  CHAT_DATA_BEGIN,
  CHAT_DATA_SUCCESS,
  CHAT_DATA_ERR,

  USER_DATA_BEGIN,
  USER_DATA_SUCCESS,
  USER_DATA_ERR,

  SERVICES_DATA_BEGIN,
  SERVICES_DATA_SUCCESS,
  SERVICES_DATA_ERR,

  ALL_USERS_DATA_BEGIN,
  ALL_USERS_DATA_SUCCESS,
  ALL_USERS_DATA_ERR,

  GET_NOTIFICATIONS_BEGIN,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_ERR,

  STAFF_DOCUMENT_DATA_BEGIN,
  STAFF_DOCUMENT_DATA_SUCCESS,
  STAFF_DOCUMENT_DATA_ERR,

  CHECK_ASSIGNMENT_RANGE_BEGIN,
  CHECK_ASSIGNMENT_RANGE_SUCCESS,
  CHECK_ASSIGNMENT_RANGE_ERR,
} = actions;

const initialState = {
  data: [],
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
  newsData: [],
  staffData: [],
  staffGeofenceData: [],
  staffGeofenceDataLoading: null,
  staffListData: [],
  categoryTypes: [],
  clientCategoryTypes: [],
  userProfileData: [],
  assignmentRangeData: [],
  userProfileDataLoading: null,
  chatData: [],
  chatDataLoading: null,
  userData: [],
  userDataLoading: null,
  servicesData: [],
  allUsersData: [],
  notificationsData: [],
  staffDocumentData: [],
  supportLoading: null,
  staffLoading: null,
  newsLoading: null,
  notificationsLoading: null,
  updateLoading: null,
  staffListDataLoading: null,
};

const initialStateSingle = {
  data: null,
  loading: false,
  error: null,
};

const fsCrudReducer = (state = initialState, action) => {
  const {
    type,
    data,
    err,
    newsData,
    staffListData,
    staffData,
    staffGeofenceData,
    categoryTypes,
    clientCategoryTypes,
    userProfileData,
    chatData,
    userData,
    servicesData,
    allUsersData,
    notificationsData,
    staffDocumentData,
    assignmentRangeData,
  } = action;
  switch (type) {
    case GET_NOTIFICATIONS_BEGIN:
      return {
        ...state,
        notificationsLoading: true,
      };

    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationsData,
        error: false,
        notificationsLoading: false,
      };

    case GET_NOTIFICATIONS_ERR:
      return {
        ...state,
        error: err,
        notificationsLoading: false,
      };

    case ALL_USERS_DATA_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ALL_USERS_DATA_SUCCESS:
      return {
        ...state,
        allUsersData,
        error: false,
        loading: false,
      };

    case ALL_USERS_DATA_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case USER_DATA_BEGIN:
      return {
        ...state,
        userDataLoading: true,
      };

    case USER_DATA_SUCCESS:
      return {
        ...state,
        userData,
        error: false,
        userDataLoading: false,
      };

    case USER_DATA_ERR:
      return {
        ...state,
        error: err,
        userDataLoading: false,
      };

    case SERVICES_DATA_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case SERVICES_DATA_SUCCESS:
      return {
        ...state,
        servicesData,
        error: false,
        loading: false,
      };

    case SERVICES_DATA_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CHAT_DATA_BEGIN:
      return {
        ...state,
        chatDataLoading: true,
      };

    case CHAT_DATA_SUCCESS:
      return {
        ...state,
        chatData,
        error: false,
        chatDataLoading: false,
      };

    case CHAT_DATA_ERR:
      return {
        ...state,
        error: err,
        chatDataLoading: false,
      };

    case USER_PROFILE_DATA_BEGIN:
      return {
        ...state,
        userProfileDataLoading: true,
      };

    case USER_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        userProfileData,
        error: false,
        userProfileDataLoading: false,
      };

    case USER_PROFILE_DATA_ERR:
      return {
        ...state,
        error: err,
        userProfileDataLoading: false,
      };

    case CATEGORY_TYPES_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CATEGORY_TYPES_SUCCESS:
      return {
        ...state,
        categoryTypes,
        error: false,
        loading: false,
      };

    case CATEGORY_TYPES_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CLIENT_CATEGORY_TYPES_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_CATEGORY_TYPES_SUCCESS:
      return {
        ...state,
        clientCategoryTypes,
        error: false,
        loading: false,
      };

    case CLIENT_CATEGORY_TYPES_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case NEWS_DATA_BEGIN:
      return {
        ...state,
        newsLoading: true,
      };

    case NEWS_DATA_SUCCESS:
      return {
        ...state,
        newsData,
        error: false,
        newsLoading: false,
      };

    case NEWS_DATA_ERR:
      return {
        ...state,
        error: err,
        newsLoading: false,
      };

    case STAFF_DATA_BEGIN:
      return {
        ...state,
        staffLoading: true,
      };

    case STAFF_DATA_SUCCESS:
      return {
        ...state,
        staffData,
        error: false,
        staffLoading: false,
      };

    case STAFF_DATA_ERR:
      return {
        ...state,
        error: err,
        staffLoading: false,
      };

    case STAFF_GEOFENCE_DATA_BEGIN:
      return {
        ...state,
        staffGeofenceDataLoading: true,
      };

    case STAFF_GEOFENCE_DATA_SUCCESS:
      return {
        ...state,
        staffGeofenceData,
        error: false,
        staffGeofenceDataLoading: false,
      };

    case STAFF_GEOFENCE_DATA_ERR:
      return {
        ...state,
        error: err,
        staffGeofenceDataLoading: false,
      };

    case STAFF_LIST_DATA_BEGIN:
      return {
        ...state,
        staffListDataLoading: true,
      };

    case STAFF_LIST_DATA_SUCCESS:
      return {
        ...state,
        staffListData,
        error: false,
        staffListDataLoading: false,
      };

    case STAFF_LIST_DATA_ERR:
      return {
        ...state,
        error: err,
        staffListDataLoading: false,
      };

    case FB_UPLOAD_BEGIN:
      return {
        ...state,
        fileLoading: true,
      };

    case FB_UPLOAD_SUCCESS:
      return {
        ...state,
        url: data,
        error: false,
        fileLoading: false,
      };

    case FB_UPLOAD_ERR:
      return {
        ...state,
        error: err,
        fileLoading: false,
      };

    case FB_ADD_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case FB_ADD_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case FB_ADD_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case SUPPORT_TICKET_BEGIN:
      return {
        ...state,
        supportLoading: true,
      };

    case SUPPORT_TICKET_SUCCESS:
      return {
        ...state,
        error: false,
        supportLoading: false,
      };

    case SUPPORT_TICKET_ERR:
      return {
        ...state,
        error: err,
        supportLoading: false,
      };

    case FB_SEARCH_BEGIN:
      return {
        ...state,
      };

    case FB_SEARCH_SUCCESS:
      return {
        ...state,
        data,
        error: false,
      };

    case FB_SEARCH_ERR:
      return {
        ...state,
        error: err,
      };

    case FB_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case FB_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case FB_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case FB_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case FB_DELETE_SUCCESS:
      return {
        ...state,
        error: false,
        data,
        loading: false,
      };

    case FB_DELETE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case FB_UPDATE_BEGIN:
      return {
        ...state,
        updateLoading: true,
      };

    case FB_UPDATE_SUCCESS:
      return {
        ...state,
        error: false,
        updateLoading: false,
      };

    case FB_UPDATE_ERR:
      return {
        ...state,
        error: err,
        updateLoading: false,
      };

    case STAFF_DOCUMENT_DATA_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case STAFF_DOCUMENT_DATA_SUCCESS:
      return {
        ...state,
        staffDocumentData,
        error: false,
        loading: false,
      };

    case STAFF_DOCUMENT_DATA_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case CHECK_ASSIGNMENT_RANGE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CHECK_ASSIGNMENT_RANGE_SUCCESS:
      return {
        ...state,
        assignmentRangeData,
        error: false,
        loading: false,
      };

    case CHECK_ASSIGNMENT_RANGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const fsSingleCrudReducer = (state = initialStateSingle, action) => {
  const { type, data, err } = action;
  switch (type) {
    case FB_SINGLE_DATA_BEGIN:
      return {
        ...initialStateSingle,
        loading: true,
      };

    case FB_SINGLE_DATA_SUCCESS:
      return {
        ...initialStateSingle,
        data,
        error: false,
        loading: false,
      };

    case FB_SINGLE_DATA_ERR:
      return {
        ...initialStateSingle,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export { fsCrudReducer, fsSingleCrudReducer };
