import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const AddUser = lazy(() => import('../../container/pages/AddUsers'));
const DataTable = lazy(() => import('../../container/pages/UserListDataTable'));

function PagesRoute() {
  const { path } = useRouteMatch();

  return (
    <>
      <Switch>
        {/* <Route exact path={`${path}`} component={DataTable} /> */}
        <Route exact path={`${path}`}>
          {(props) => <DataTable type="preferredStaff" {...props} />}
        </Route>
        <Route path={`${path}/addStaff`} component={AddUser} />
      </Switch>
    </>
  );
}

export default PagesRoute;
