import firebase from 'firebase';
import actions from './actions';

const {
  fbLoginBegin,
  fbLoginSuccess,
  fbLoginErr,
  fbLogOutBegin,
  fbLogOutSuccess,
  fbLogOutErr,
  fbSignUpBegin,
  fbSignUpSuccess,
  fbSignUpErr,
  fbCheckBegin,
  fbCheckSuccess,
  fbCheckErr,
} = actions;

const fbAuthLogin = (data) => {
  return async (dispatch, getState, { getFirebase }) => {
    const fb = getFirebase();
    const { username, password } = data;

    // * Handle the case where the username might be an email
    if (username.includes('@')) {
      try {
        await dispatch(fbLoginBegin());
        await fb.auth().signInWithEmailAndPassword(username, password);
        await dispatch(fbLoginSuccess());
      } catch (authError) {
        console.error('Error during sign in with email and password:', authError);
        await dispatch(fbLoginErr(authError));
      }
      return;
    }
    // * Getting the cloud function to get the email based on the username
    console.log('Login in with user:', username);
    const getEmailbyUsername = await firebase.functions().httpsCallable('getEmailbyUsername');

    // * Call the Cloud Function to get the email
    try {
      const result = await getEmailbyUsername({ username });

      const { email } = result.data;
      // Now authenticate the user with the retrieved email
      try {
        await dispatch(fbLoginBegin());
        await fb.auth().signInWithEmailAndPassword(email, password);

        await dispatch(fbCheckBegin());

        await dispatch(fbLoginSuccess());
      } catch (authError) {
        console.error('Error during sign in with retrieved email and password:', authError);
        await dispatch(fbLoginErr(authError));
      }
    } catch (error) {
      console.error('Error calling Cloud Function:', error);
      await dispatch(fbLoginErr(error));
    }
  };
};

const fbAuthLogout = () => {
  return async (dispatch, getState, { getFirebase }) => {
    const fb = getFirebase();

    try {
      await dispatch(fbLogOutBegin());
      await fb
        .auth()
        .signOut()
        .then(() => {
          console.log('done');
        });
      await dispatch(fbLogOutSuccess(null));
    } catch (err) {
      await dispatch(fbLogOutErr(err));
    }
  };
};

const fbAuthCheck = () => {
  return async (dispatch) => {
    try {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          const userRef = firebase.firestore().collection('users').doc(user.uid);
          userRef.get().then((doc) => {
            if (doc.exists && doc.data().role === 'client') {
              dispatch(fbCheckSuccess(user));
            } else {
              // console.log('else condition');
              firebase.auth().signOut();
              alert('Access denied');
            }
          });
        } else {
          dispatch(fbLogOutSuccess());
        }
      });
    } catch (err) {
      await dispatch(fbCheckErr(err));
    }
  };
};

const fbAuthSignUp = (newUser) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const fb = getFirebase();
    try {
      await dispatch(fbSignUpBegin());
      const resp = await fb.auth().createUserWithEmailAndPassword(newUser.email, newUser.password);
      await db
        .collection('users')
        .doc(resp.user.uid)
        .set({
          ...newUser,
        });
      await dispatch(fbSignUpSuccess());
    } catch (err) {
      await dispatch(fbSignUpErr(err));
    }
  };
};

const fbAuthLoginWithGoogle = () => {
  return async (dispatch, getState, { getFirebase }) => {
    const fb = getFirebase();
    const provider = new fb.auth.GoogleAuthProvider();
    try {
      await dispatch(fbLoginBegin());
      const result = await fb.auth().signInWithPopup(provider);
      await dispatch(fbLoginSuccess(result));
    } catch (err) {
      await dispatch(fbLoginErr(err));
    }
  };
};

const fbAuthLoginWithFacebook = () => {
  return async (dispatch, getState, { getFirebase }) => {
    const fb = getFirebase();
    const provider = new fb.auth.FacebookAuthProvider();
    try {
      await dispatch(fbLoginBegin());
      const result = await fb.auth().signInWithPopup(provider);
      await dispatch(fbLoginSuccess(result));
    } catch (err) {
      await dispatch(fbLoginErr(err));
    }
  };
};

export { fbAuthLogin, fbAuthLogout, fbAuthSignUp, fbAuthLoginWithGoogle, fbAuthLoginWithFacebook, fbAuthCheck };
