import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';

import PreferredStaff from './users';
// import Services from './services';
// import Categories from './categories';
import withAdminLayout from '../../layout/withAdminLayout';

// const Calendar = lazy(() => import('./calendar'));
const Calendar = lazy(() => import('../../container/calendar/Calendar'));
const Staff = lazy(() => import('./clients'));
const Chat = lazy(() => import('../../container/chat/ChatApp'));
const News = lazy(() => import('./news'));
const Maps = lazy(() => import('./maps'));
const Settings = lazy(() => import('../../container/settings/Settings'));
const Notifications = lazy(() => import('../../container/notifications/Activity'));
const Support = lazy(() => import('../../container/pages/support'));
// const Guidelines = lazy(() => import('./guidelines'));
// const Invoices = lazy(() => import('../../container/invoice'));
// const Announcements = lazy(() => import('./announcements'));

const Admin = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Redirect exact from={path} to={`${path}/calendar/month`} />

      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route path={`${path}/calendar`} component={Calendar} />
        <Route path={`${path}/staff`} component={Staff} />
        <Route path={`${path}/preferredStaff`} component={PreferredStaff} />
        <Route path={`${path}/notifications`} component={Notifications} />
        <Route path={`${path}/news`} component={News} />
        <Route path={`${path}/maps`} component={Maps} />
        <Route path={`${path}/main/chat`} component={Chat} />
        <Route path={`${path}/settings`} component={Settings} />
        <Route path={`${path}/support`} component={Support} />
        {/* <Route path={`${path}/services`} component={Services} />
        <Route path={`${path}/categories`} component={Categories} /> */}
        {/* <Route path={`${path}/announcements`} component={Announcements} /> */}
        {/*  <Route path={`${path}/invoices`} component={Invoices} /> */}
        {/* <Route path={`${path}/guidelines`} component={Guidelines} /> */}
      </Suspense>
    </Switch>
  );
};

export default withAdminLayout(Admin);
