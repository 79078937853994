const actions = {
  FB_ADD_BEGIN: 'FB_ADD_BEGIN',
  FB_ADD_SUCCESS: 'FB_ADD_SUCCESS',
  FB_ADD_ERR: 'FB_ADD_ERR',

  SUPPORT_TICKET_BEGIN: 'SUPPORT_TICKET_BEGIN',
  SUPPORT_TICKET_SUCCESS: 'SUPPORT_TICKET_SUCCESS',
  SUPPORT_TICKET_ERR: 'SUPPORT_TICKET_ERR',

  FB_READ_BEGIN: 'FB_READ_BEGIN',
  FB_READ_SUCCESS: 'FB_READ_SUCCESS',
  FB_READ_ERR: 'FB_READ_ERR',

  FB_UPDATE_BEGIN: 'FB_UPDATE_BEGIN',
  FB_UPDATE_SUCCESS: 'FB_UPDATE_SUCCESS',
  FB_UPDATE_ERR: 'FB_UPDATE_ERR',

  FB_DELETE_BEGIN: 'FB_DELETE_BEGIN',
  FB_DELETE_SUCCESS: 'FB_DELETE_SUCCESS',
  FB_DELETE_ERR: 'FB_DELETE_ERR',

  FB_SINGLE_DATA_BEGIN: 'FB_SINGLE_DATA_BEGIN',
  FB_SINGLE_DATA_SUCCESS: 'FB_SINGLE_DATA_SUCCESS',
  FB_SINGLE_DATA_ERR: 'FB_SINGLE_DATA_ERR',

  FB_UPLOAD_BEGIN: 'FB_UPLOAD_BEGIN',
  FB_UPLOAD_SUCCESS: 'FB_UPLOAD_SUCCESS',
  FB_UPLOAD_ERR: 'FB_UPLOAD_ERR',

  FB_SEARCH_BEGIN: 'FB_SEARCH_BEGIN',
  FB_SEARCH_SUCCESS: 'FB_SEARCH_SUCCESS',
  FB_SEARCH_ERR: 'FB_SEARCH_ERR',

  NEWS_DATA_BEGIN: 'NEWS_DATA_BEGIN',
  NEWS_DATA_SUCCESS: 'NEWS_DATA_SUCCESS',
  NEWS_DATA_ERR: 'NEWS_DATA_ERR',

  STAFF_DATA_BEGIN: 'STAFF_DATA_BEGIN',
  STAFF_DATA_SUCCESS: 'STAFF_DATA_SUCCESS',
  STAFF_DATA_ERR: 'STAFF_DATA_ERR',

  STAFF_GEOFENCE_DATA_BEGIN: 'STAFF_GEOFENCE_DATA_BEGIN',
  STAFF_GEOFENCE_DATA_SUCCESS: 'STAFF_GEOFENCE_DATA_SUCCESS',
  STAFF_GEOFENCE_DATA_ERR: 'STAFF_GEOFENCE_DATA_ERR',

  STAFF_LIST_DATA_BEGIN: 'STAFF_LIST_DATA_BEGIN',
  STAFF_LIST_DATA_SUCCESS: 'STAFF_LIST_DATA_SUCCESS',
  STAFF_LIST_DATA_ERR: 'STAFF_LIST_DATA_ERR',

  CATEGORY_TYPES_BEGIN: 'CATEGORY_TYPES_BEGIN',
  CATEGORY_TYPES_SUCCESS: 'CATEGORY_TYPES_SUCCESS',
  CATEGORY_TYPES_ERR: 'CATEGORY_TYPES_ERR',

  CLIENT_CATEGORY_TYPES_BEGIN: 'CLIENT_CATEGORY_TYPES_BEGIN',
  CLIENT_CATEGORY_TYPES_SUCCESS: 'CLIENT_CATEGORY_TYPES_SUCCESS',
  CLIENT_CATEGORY_TYPES_ERR: 'CLIENT_CATEGORY_TYPES_ERR',

  USER_PROFILE_DATA_BEGIN: 'USER_PROFILE_DATA_BEGIN',
  USER_PROFILE_DATA_SUCCESS: 'USER_PROFILE_DATA_SUCCESS',
  USER_PROFILE_DATA_ERR: 'USER_PROFILE_DATA_ERR',

  CHAT_DATA_BEGIN: 'CHAT_DATA_BEGIN',
  CHAT_DATA_SUCCESS: 'CHAT_DATA_SUCCESS',
  CHAT_DATA_ERR: 'CHAT_DATA_ERR',

  USER_DATA_BEGIN: 'USER_DATA_BEGIN',
  USER_DATA_SUCCESS: 'USER_DATA_SUCCESS',
  USER_DATA_ERR: 'USER_DATA_ERR',

  SERVICES_DATA_BEGIN: 'SERVICES_DATA_BEGIN',
  SERVICES_DATA_SUCCESS: 'SERVICES_DATA_SUCCESS',
  SERVICES_DATA_ERR: 'SERVICES_DATA_ERR',

  ALL_USERS_DATA_BEGIN: 'ALL_USERS_DATA_BEGIN',
  ALL_USERS_DATA_SUCCESS: 'ALL_USERS_DATA_SUCCESS',
  ALL_USERS_DATA_ERR: 'ALL_USERS_DATA_ERR',

  GET_NOTIFICATIONS_BEGIN: 'GET_NOTIFICATIONS_BEGIN',
  GET_NOTIFICATIONS_SUCCESS: 'GET_NOTIFICATIONS_SUCCESS',
  GET_NOTIFICATIONS_ERR: 'GET_NOTIFICATIONS_ERR',

  STAFF_DOCUMENT_DATA_BEGIN: 'STAFF_DOCUMENT_DATA_BEGIN',
  STAFF_DOCUMENT_DATA_SUCCESS: 'STAFF_DOCUMENT_DATA_SUCCESS',
  STAFF_DOCUMENT_DATA_ERR: 'STAFF_DOCUMENT_DATA_ERR',

  CHECK_ASSIGNMENT_RANGE_BEGIN: 'CHECK_ASSIGNMENT_RANGE_BEGIN',
  CHECK_ASSIGNMENT_RANGE_SUCCESS: 'CHECK_ASSIGNMENT_RANGE_SUCCESS',
  CHECK_ASSIGNMENT_RANGE_ERR: 'CHECK_ASSIGNMENT_RANGE_ERR',

  getNotificationsBegin: () => {
    return {
      type: actions.GET_NOTIFICATIONS_BEGIN,
    };
  },

  getNotificationsSuccess: (notificationsData) => {
    return {
      type: actions.GET_NOTIFICATIONS_SUCCESS,
      notificationsData,
    };
  },

  getNotificationsErr: (err) => {
    return {
      type: actions.GET_NOTIFICATIONS_ERR,
      err,
    };
  },

  allUsersDataBegin: () => {
    return {
      type: actions.ALL_USERS_DATA_BEGIN,
    };
  },

  allUsersDataSuccess: (allUsersData) => {
    return {
      type: actions.ALL_USERS_DATA_SUCCESS,
      allUsersData,
    };
  },

  allUsersDataErr: (err) => {
    return {
      type: actions.ALL_USERS_DATA_ERR,
      err,
    };
  },

  userDataBegin: () => {
    return {
      type: actions.USER_DATA_BEGIN,
    };
  },

  userDataSuccess: (userData) => {
    return {
      type: actions.USER_DATA_SUCCESS,
      userData,
    };
  },

  userDataErr: (err) => {
    return {
      type: actions.USER_DATA_ERR,
      err,
    };
  },

  chatDataBegin: () => {
    return {
      type: actions.CHAT_DATA_BEGIN,
    };
  },

  chatDataSuccess: (chatData) => {
    return {
      type: actions.CHAT_DATA_SUCCESS,
      chatData,
    };
  },

  chatDataErr: (err) => {
    return {
      type: actions.CHAT_DATA_ERR,
      err,
    };
  },

  userProfileDataBegin: () => {
    return {
      type: actions.USER_PROFILE_DATA_BEGIN,
    };
  },

  userProfileDataSuccess: (userProfileData) => {
    return {
      type: actions.USER_PROFILE_DATA_SUCCESS,
      userProfileData,
    };
  },

  userProfileDataErr: (err) => {
    return {
      type: actions.USER_PROFILE_DATA_ERR,
      err,
    };
  },

  categoryTypesBegin: () => {
    return {
      type: actions.CATEGORY_TYPES_BEGIN,
    };
  },

  categoryTypesSuccess: (categoryTypes) => {
    return {
      type: actions.CATEGORY_TYPES_SUCCESS,
      categoryTypes,
    };
  },

  categoryTypesErr: (err) => {
    return {
      type: actions.CATEGORY_TYPES_ERR,
      err,
    };
  },

  clientCategoryTypesBegin: () => {
    return {
      type: actions.CLIENT_CATEGORY_TYPES_BEGIN,
    };
  },

  clientCategoryTypesSuccess: (clientCategoryTypes) => {
    return {
      type: actions.CLIENT_CATEGORY_TYPES_SUCCESS,
      clientCategoryTypes,
    };
  },

  clientCategoryTypesErr: (err) => {
    return {
      type: actions.CLIENT_CATEGORY_TYPES_ERR,
      err,
    };
  },

  newsDataBegin: () => {
    return {
      type: actions.NEWS_DATA_BEGIN,
    };
  },

  newsDataSuccess: (newsData) => {
    return {
      type: actions.NEWS_DATA_SUCCESS,
      newsData,
    };
  },

  newsDataErr: (err) => {
    return {
      type: actions.NEWS_DATA_ERR,
      err,
    };
  },

  staffDataBegin: () => {
    return {
      type: actions.STAFF_DATA_BEGIN,
    };
  },

  staffDataSuccess: (staffData) => {
    return {
      type: actions.STAFF_DATA_SUCCESS,
      staffData,
    };
  },

  staffDataErr: (err) => {
    return {
      type: actions.STAFF_DATA_ERR,
      err,
    };
  },

  staffGeofenceDataBegin: () => {
    return {
      type: actions.STAFF_GEOFENCE_DATA_BEGIN,
    };
  },

  staffGeofenceDataSuccess: (staffGeofenceData) => {
    return {
      type: actions.STAFF_GEOFENCE_DATA_SUCCESS,
      staffGeofenceData,
    };
  },

  staffGeofenceDataErr: (err) => {
    return {
      type: actions.STAFF_GEOFENCE_DATA_ERR,
      err,
    };
  },

  staffListDataBegin: () => {
    return {
      type: actions.STAFF_LIST_DATA_BEGIN,
    };
  },

  staffListDataSuccess: (staffListData) => {
    return {
      type: actions.STAFF_LIST_DATA_SUCCESS,
      staffListData,
    };
  },

  staffListDataErr: (err) => {
    return {
      type: actions.STAFF_LIST_DATA_ERR,
      err,
    };
  },

  fbSearchBegin: () => {
    return {
      type: actions.FB_SEARCH_BEGIN,
    };
  },

  fbSearchSuccess: (data) => {
    return {
      type: actions.FB_SEARCH_SUCCESS,
      data,
    };
  },

  fbSearchErr: (err) => {
    return {
      type: actions.FB_SEARCH_ERR,
      err,
    };
  },

  fbUploadBegin: () => {
    return {
      type: actions.FB_UPLOAD_BEGIN,
    };
  },

  fbUploadSuccess: (data) => {
    return {
      type: actions.FB_UPLOAD_SUCCESS,
      data,
    };
  },

  fbUploadErr: (err) => {
    return {
      type: actions.FB_UPLOAD_ERR,
      err,
    };
  },

  fbAddBegin: () => {
    return {
      type: actions.FB_ADD_BEGIN,
    };
  },

  fbAddSuccess: (data) => {
    return {
      type: actions.FB_ADD_SUCCESS,
      data,
    };
  },

  fbAddErr: (err) => {
    return {
      type: actions.FB_ADD_ERR,
      err,
    };
  },

  supportTicketBegin: () => {
    return {
      type: actions.SUPPORT_TICKET_BEGIN,
    };
  },

  supportTicketSuccess: () => {
    return {
      type: actions.SUPPORT_TICKET_SUCCESS,
    };
  },

  supportTicketErr: (err) => {
    return {
      type: actions.SUPPORT_TICKET_ERR,
      err,
    };
  },

  fbReadBegin: () => {
    return {
      type: actions.FB_READ_BEGIN,
    };
  },

  fbReadSuccess: (data) => {
    return {
      type: actions.FB_READ_SUCCESS,
      data,
    };
  },

  fbReadErr: (err) => {
    return {
      type: actions.FB_READ_ERR,
      err,
    };
  },

  fbUpdateBegin: () => {
    return {
      type: actions.FB_UPDATE_BEGIN,
    };
  },

  fbUpdateSuccess: () => {
    return {
      type: actions.FB_UPDATE_SUCCESS,
    };
  },

  fbUpdateErr: (err) => {
    return {
      type: actions.FB_UPDATE_ERR,
      err,
    };
  },

  fbDeleteBegin: () => {
    return {
      type: actions.FB_DELETE_BEGIN,
    };
  },

  fbDeleteSuccess: (data) => {
    return {
      type: actions.FB_DELETE_SUCCESS,
      data,
    };
  },

  fbDeleteErr: (err) => {
    return {
      type: actions.FB_DELETE_ERR,
      err,
    };
  },

  fbSingleDataBegin: () => {
    return {
      type: actions.FB_SINGLE_DATA_BEGIN,
    };
  },

  fbSingleDataSuccess: (data) => {
    return {
      type: actions.FB_SINGLE_DATA_SUCCESS,
      data,
    };
  },

  fbSingleDataErr: (err) => {
    return {
      type: actions.FB_SINGLE_DATA_ERR,
      err,
    };
  },

  servicesDataBegin: () => {
    return {
      type: actions.SERVICES_DATA_BEGIN,
    };
  },

  servicesDataSuccess: (servicesData) => {
    return {
      type: actions.SERVICES_DATA_SUCCESS,
      servicesData,
    };
  },

  servicesDataErr: (err) => {
    return {
      type: actions.SERVICES_DATA_ERR,
      err,
    };
  },

  staffDocumentDataBegin: () => {
    return {
      type: actions.STAFF_DOCUMENT_DATA_BEGIN,
    };
  },

  staffDocumentDataSuccess: (staffDocumentData) => {
    return {
      type: actions.STAFF_DOCUMENT_DATA_SUCCESS,
      staffDocumentData,
    };
  },

  staffDocumentDataErr: (err) => {
    return {
      type: actions.STAFF_DOCUMENT_DATA_ERR,
      err,
    };
  },

  checkAssignmentRangeBegin: () => {
    return {
      type: actions.CHECK_ASSIGNMENT_RANGE_BEGIN,
    };
  },

  checkAssignmentRangeSuccess: (assignmentRangeData) => {
    return {
      type: actions.CHECK_ASSIGNMENT_RANGE_SUCCESS,
      assignmentRangeData,
    };
  },

  checkAssignmentRangeErr: (err) => {
    return {
      type: actions.CHECK_ASSIGNMENT_RANGE_ERR,
      err,
    };
  },
};

export default actions;
