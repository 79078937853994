import React, { useEffect, useState } from 'react';
import { Menu, Badge } from 'antd';
import firebase from 'firebase';
import { NavLink, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import { useSelector } from 'react-redux';

const MenuItems = ({ darkMode, toggleCollapsed, topMenu }) => {
  const { path } = useRouteMatch();
  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const [totalGroupChat, setTotalGroupChat] = useState(0);
  const [totalPrivateChat, setTotalPrivateChat] = useState(0);

  const { chatData } = useSelector((state) => {
    return {
      chatData: state.crud.chatData,
    };
  });

  useEffect(() => {
    setTotalPrivateChat(0);
    setTotalGroupChat(0);
    chatData
      .filter((chat) => !chat.groupName)
      .map((chat) => {
        if (chat.unReadBy) {
          if (chat.unReadBy.find((unReadBy) => unReadBy === firebase.auth().currentUser.uid)) {
            setTotalPrivateChat(totalPrivateChat + 1);
          }
        }
        return null;
      });

    chatData
      .filter((chat) => chat.groupName)
      .map((chat) => {
        if (chat.unReadBy) {
          if (chat.unReadBy.find((unReadBy) => unReadBy === firebase.auth().currentUser.uid)) {
            setTotalGroupChat(totalGroupChat + 1);
          }
        }
        return null;
      });
  }, [chatData]);

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );
  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const items = [
    {
      key: 'month',
      icon: !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/calendar/month`}>
          <FeatherIcon icon="calendar" />
        </NavLink>
      ),
      label: (
        <NavLink onClick={toggleCollapsed} to={`${path}/calendar/month`}>
          Calendar
        </NavLink>
      ),
    },
    {
      key: 'staff',
      icon: !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/staff`}>
          <FeatherIcon icon="user" />
        </NavLink>
      ),
      label: (
        <NavLink onClick={toggleCollapsed} to={`${path}/staff`}>
          Staff
        </NavLink>
      ),
    },
    {
      key: 'preferredStaff',
      icon: !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/preferredStaff`}>
          <FeatherIcon icon="star" />
        </NavLink>
      ),
      label: (
        <NavLink onClick={toggleCollapsed} to={`${path}/preferredStaff`}>
          Preferred Staff
        </NavLink>
      ),
    },
    {
      key: 'notifications',
      icon: !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/notifications`}>
          <FeatherIcon icon="bell" />
        </NavLink>
      ),
      label: (
        <NavLink onClick={toggleCollapsed} to={`${path}/notifications`}>
          Notifications
        </NavLink>
      ),
    },
    {
      key: 'news',
      icon: !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/news`}>
          <FeatherIcon icon="file" />
        </NavLink>
      ),
      label: (
        <NavLink onClick={toggleCollapsed} to={`${path}/news`}>
          News
        </NavLink>
      ),
    },
    {
      key: 'geofence',
      icon: !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/maps/geofence`}>
          <FeatherIcon icon="map" />
        </NavLink>
      ),
      label: (
        <NavLink onClick={toggleCollapsed} to={`${path}/maps/geofence`}>
          Geofence
        </NavLink>
      ),
    },
    {
      key: 'chat',
      icon: !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/main/chat/private`}>
          <FeatherIcon icon="message-square" />
        </NavLink>
      ),
      label: (
        <NavLink onClick={toggleCollapsed} to={`${path}/main/chat/private`}>
          Chat
          <Badge dot={totalGroupChat || totalPrivateChat} />
        </NavLink>
      ),
    },
    {
      key: 'profile',
      icon: !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/settings/profile`}>
          <FeatherIcon icon="settings" />
        </NavLink>
      ),
      label: (
        <NavLink onClick={toggleCollapsed} to={`${path}/settings/profile`}>
          Profile Settings
        </NavLink>
      ),
    },
    {
      key: 'support',
      icon: !topMenu && (
        <ReactSVG className="sDash_menu-item-icon" src={require('../static/img/icon/headphone.svg').default} />
      ),
      label: (
        <NavLink onClick={toggleCollapsed} to={`${path}/support`}>
          Support Center
        </NavLink>
      ),
    },
  ];
  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
      items={items}
    />
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
