/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import firebase from 'firebase';

function UserImage({ url, imgStyle }) {
  const [image, setImage] = useState('');

  useEffect(() => {
    if (url) {
      if (url.includes('https://')) {
        setImage(url);
      } else {
        firebase
          .storage()
          .ref(url)
          .getDownloadURL()
          .then((downloadUrl) => {
            setImage(downloadUrl);
          });
      }
    } else {
      setImage(require('../../static/img/users/1.png'));
    }
  }, [url]);

  return <img src={image} alt="Uploaded" style={{ height: '120px', width: '120px', ...imgStyle }} />;
}

export { UserImage };
